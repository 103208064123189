import Button from "@material-ui/core/Button";
import styled from "styled-components";

const SpacedButton = styled(Button)`
  margin: ${(props: { margin?: string }) => {
    return props.margin || "2rem";
  }};
`;

export default SpacedButton;
