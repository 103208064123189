import styled from "styled-components";

const WhitesmokeContainer = styled.div`
  background-color: whitesmoke;
  padding: 5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default WhitesmokeContainer;
