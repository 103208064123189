import React, { useEffect, useState } from "react";

import MomentUtils from "@date-io/moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AxiosError } from "axios";
import { navigate } from "gatsby";
import * as moment from "moment";
import * as momentime from "moment-timezone";
import styled from "styled-components";
import Swal from "sweetalert2";

import SpacedButton from "~/components/Buttons/SpacedButton";
import Flex from "~/components/Containers/Flex";
import WhitesmokeContainer from "~/components/Containers/WhitesmokeContainer";
import LoadingError from "~/components/Loaders/LoadingError";
import Bold from "~/components/Typography/Bold";
import appointmentService from "~/utils/api/v1/appointmentService";
import NurseAPI from "~/utils/api/v2/nurse";
import { getAllCountryTimeblocks } from "~/utils/api/v2/timeblocks";
import { COUNTRY_TO_CODE, DEFAULT_TIMEZONE } from "~/utils/data/constants";
import { DetailedAppointment } from "~/utils/interfaces/Appointment";
import { TimeBlock } from "~/utils/interfaces/Timeblock";

moment.locale("es");

const Spaced = styled.div`
  margin: 1rem;
`;

const SelectorWrapper = styled(FormControl)`
  margin: 0rem 1rem;

  .MuiFormControl-marginNormal {
    margin: 0px;
  }
`;

interface Props {
  id: string;
  onBack: () => void;
}

const SuperadminReschedule = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updatingAppointment, setUpdatingAppointment] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | {}>({});
  const [date, setDate] = useState<moment.Moment>(moment());
  //! Type this three states
  const [appointmentData, setAppointmentData] = useState<DetailedAppointment | undefined>(undefined);
  const [timeblocks, setTimeblocks] = useState<TimeBlock[]>([]);
  const [selectedTimeblock, setSelectedTimeblock] = useState<string>("09:00:00-09:30:00");
  const [timezone, setTimezone] = useState<"America/Santiago" | "America/Mexico_City">("America/Santiago");

  const fetchData = async () => {
    // fetchs the appointment data (for previous date and time info)
    // and the timeblocks
    setLoading(true);
    try {
      const responseAppointments = await appointmentService.fetchAppointment(props.id);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const timeblocks = await getAllCountryTimeblocks(COUNTRY_TO_CODE[responseAppointments.data.country]);
      setAppointmentData(responseAppointments.data);
      setTimezone(responseAppointments.data.safe_timezone);
      setTimeblocks(timeblocks || []);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const updateAppoimntment = async (): Promise<void> => {
    setUpdatingAppointment(true);
    try {
      const tb = selectedTimeblock.split("-");
      const begin = momentime.tz(`${date.format("YYYY-MM-DD")} ${tb[0]}`, timezone);
      const end = momentime.tz(`${date.format("YYYY-MM-DD")} ${tb[1]}`, timezone);

      const formattedBegin = begin.tz(timezone).format();
      const res = await NurseAPI.checkAvailable(props.id, formattedBegin);
      if (!res.available) {
        const proceedAnyways = await Swal.fire({
          title: "Este bloque no se encuentra disponible. Continuar de todas maneras?",
          icon: "warning",
          showCancelButton: true,
        });

        if (!proceedAnyways.isConfirmed) {
          return;
        }
      }

      const req = await appointmentService.rescheduleAppointment(props.id, {
        begin_date: `${begin.tz(timezone).format()}`,
        work_period_max_lateness: `${end.tz(timezone).format()}`,
      });
      const notif = await Swal.fire({
        icon: "success",
        title: "Cita re-agendada!",
        showCloseButton: false,
      });
      if (notif.isConfirmed || notif.isDismissed) {
        navigate(`/appointment/${props.id}/`);
      }
    } catch (err) {
      setError(err);
    }
    setUpdatingAppointment(false);
  };

  const displayTimeblockOptions = (): JSX.Element[] => {
    const filteredTimeblocks = timeblocks.filter((timeblck) => timeblck.day === "monday");
    return filteredTimeblocks.map((tblk: TimeBlock) => {
      return (
        <MenuItem
          key={tblk.id}
          value={`${tblk.begin_hour}-${tblk.end_hour}`}
        >
          {" "}
          {tblk.begin_hour} - {tblk.end_hour}{" "}
        </MenuItem>
      );
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && (
        <WhitesmokeContainer>
          <h1>
            Re-agendar <i>appointment</i>
          </h1>

          <Spaced>
            <Bold>Fecha anterior: </Bold> {appointmentData?.displayable_begin_date || ""}
          </Spaced>

          <Spaced>
            <Flex align="center">
              <SelectorWrapper>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale="es"
                >
                  <KeyboardDatePicker
                    disabled={updatingAppointment}
                    variant="inline"
                    format="LL"
                    margin="normal"
                    label="Fecha inicio"
                    value={date}
                    autoOk
                    onChange={(date: moment.Moment) => {
                      const today = moment().startOf("day");
                      if (date >= today) {
                        setDate(date);
                      } else {
                        Swal.fire({
                          icon: "error",
                          title: "Fecha inválida",
                          text: "La fecha seleccionada debe ser mayor a la fecha actual",
                        });
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </SelectorWrapper>
              <SelectorWrapper>
                <InputLabel> Hora </InputLabel>
                <Select
                  disabled={updatingAppointment}
                  value={selectedTimeblock}
                  onChange={(e) => {
                    setSelectedTimeblock(e.target.value as string);
                  }}
                >
                  {displayTimeblockOptions()}
                </Select>
              </SelectorWrapper>
            </Flex>
          </Spaced>

          <p>La zona horaria de este appointment es {appointmentData?.safe_timezone || DEFAULT_TIMEZONE}</p>

          <Flex align="center">
            <SpacedButton
              variant="outlined"
              color="secondary"
              onClick={props.onBack}
              disabled={updatingAppointment}
            >
              Volver
            </SpacedButton>
            <SpacedButton
              variant="outlined"
              color="primary"
              onClick={updateAppoimntment}
              disabled={updatingAppointment}
            >
              Aceptar
            </SpacedButton>
          </Flex>
        </WhitesmokeContainer>
      )}
    </>
  );
};

export default SuperadminReschedule;
